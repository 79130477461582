import React from "react";
import "./Aboutme.css";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "react-bootstrap";
import { SocialIcon } from "react-social-icons";

export default function Aboutme() {
  const { t } = useTranslation();

  const socialLinks = [
    { id: 0, url: "https://twitter.com/gretzke" },
    { id: 1, url: "https://github.com/gretzke" },
    { id: 2, url: "https://www.linkedin.com/in/gretzke/" },
    { id: 3, url: "mailto:daniel@gretzke.de" },
  ];
  return (
    <Row>
      <Col>
        <Card className="Aboutme-card shadow content-wrapper" bg="dark">
          <Card.Body>
            <div className="Aboutme-social text-center">
              {socialLinks.map((link) => {
                return (
                  <SocialIcon
                    key={link.id}
                    url={link.url}
                    className="Aboutme-social-button"
                    bgColor="#999999"
                    target="_blank"
                  />
                );
              })}
            </div>
            <blockquote className="blockquote">
              <p className="mb-0" style={{ fontSize: "16px" }}>
                You miss 100% of the shots you don't take
              </p>
              <footer className="blockquote-footer">
                <cite title="Source Title">Wayne Gretzky</cite>
              </footer>
            </blockquote>
            <Card.Text>{t("header.aboutme")}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
