import React from "react";
import Navbar from "react-bootstrap/Navbar";

export default function Footer() {
  return (
    <Navbar bg="dark" sticky="bottom">
      <span className="text-white">
        &copy; {new Date().getFullYear()} Daniel Gretzke
      </span>
    </Navbar>
  );
}
