import React, { useState } from "react";
import "./Experience.css";

import { useTranslation } from "react-i18next";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

export default function Experience() {
  const { t } = useTranslation();

  const experiences = [
    {
      id: "uniswap",
      title: "Uniswap Labs",
      location: "location.remote",
      timeBegin: new Date("05/01/2024"),
      timeEnd: "present",
      description: "experience.uniswap.description",
      additionalInfoTitle: "experience.uniswap.additionalInfoTitle",
      additionalInfo: "experience.uniswap.additionalInfo",
      img: "uniswap.png",
      collapseState: useState(false),
    },
    {
      id: "polygon",
      title: "Polygon Labs",
      location: "location.remote",
      timeBegin: new Date("07/01/2022"),
      timeEnd: new Date("05/01/2024"),
      description: "experience.polygon.description",
      additionalInfoTitle: "experience.polygon.additionalInfoTitle",
      additionalInfo: "experience.polygon.additionalInfo",
      img: "polygon.png",
      collapseState: useState(false),
    },
    {
      id: "spectral",
      title: "Spectral",
      location: "location.remote",
      timeBegin: new Date("12/01/2021"),
      timeEnd: new Date("07/01/2022"),
      description: "experience.spectral.description",
      additionalInfoTitle: "experience.spectral.additionalInfoTitle",
      additionalInfo: "experience.spectral.additionalInfo",
      img: "spectral.jpg",
      collapseState: useState(false),
    },
    {
      id: "inacta",
      title: "inacta AG",
      location: "location.zug",
      timeBegin: new Date("08/01/2020"),
      timeEnd: new Date("11/01/2021"),
      description: "experience.inacta.description",
      additionalInfoTitle: "experience.inacta.additionalInfoTitle",
      additionalInfo: "experience.inacta.additionalInfo",
      img: "inacta.png",
      collapseState: useState(false),
    },
    {
      id: "micobo",
      title: "micobo GmbH",
      location: "location.fr",
      timeBegin: new Date("08/01/2019"),
      timeEnd: new Date("07/01/2020"),
      description: "experience.micobo.description",
      additionalInfoTitle: "experience.micobo.additionalInfoTitle",
      additionalInfo: "experience.micobo.additionalInfo",
      img: "micobo.png",
      collapseState: useState(false),
    },
    {
      id: "innochain",
      title: "InnoChain UG",
      location: "location.kl",
      timeBegin: new Date("07/01/2017"),
      timeEnd: new Date("01/01/2020"),
      description: "experience.innochain.description",
      additionalInfoTitle: "experience.innochain.additionalInfoTitle",
      img: "innochain.png",
      collapseState: useState(false),
    },
    {
      id: "asi",
      title: "ASI System-Engineering GmbH & Co. KG",
      location: "location.niederweiler",
      timeBegin: new Date("04/01/2016"),
      timeEnd: new Date("12/01/2017"),
      description: "experience.asi.description",
      additionalInfoTitle: "experience.asi.additionalInfoTitle",
      additionalInfo: "experience.asi.additionalInfo",
      img: "asi.png",
      collapseState: useState(false),
    },
  ];
  return (
    <VerticalTimeline>
      {experiences.map((experience) => {
        return (
          <VerticalTimelineElement
            key={experience.id}
            className="vertical-timeline-element--work"
            contentStyle={{ backgroundColor: "var(--dark)" }}
            date={
              t("date.MMMMYYYY", { date: experience.timeBegin }) +
              " – " +
              t("date.MMMMYYYY", { date: experience.timeEnd })
            }
            iconStyle={{ background: "#fff", color: "#fff" }}
            icon={
              <Image
                src={"./assets/img/" + experience.img}
                roundedCircle
                className="Experience-logo"
              />
            }
          >
            <h3 className="vertical-timeline-element-title">
              {experience.title}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ fontWeight: "600" }}
            >
              {t(experience.description)}
            </h4>
            <span>{t(experience.location)}</span>
            <br />
            {"additionalInfoTitle" in experience ? (
              <div>
                <Button
                  variant="secondary"
                  size="sm"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    experience.collapseState[1](!experience.collapseState[0]);
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={experience.collapseState[0]}
                >
                  {experience.collapseState[0]
                    ? t("util.showless")
                    : t("util.showmore")}
                </Button>
                <Collapse in={experience.collapseState[0]}>
                  <div>
                    <br />
                    <b>{t(experience.additionalInfoTitle)}</b>
                    <br />
                    {"additionalInfo" in experience ? (
                      <ul>
                        {t(experience.additionalInfo, {
                          returnObjects: true,
                        }).map((info) => {
                          return <li key={info}>{info}</li>;
                        })}
                      </ul>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Collapse>
              </div>
            ) : (
              <div></div>
            )}
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
}
