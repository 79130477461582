import React, { useState } from "react";
import "./Projects.css";
import { Trans, useTranslation } from "react-i18next";

import Switch from "react-switch";
import { Row, Col, Image } from "react-bootstrap";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

export default function Projects() {
  const { t } = useTranslation();

  // state handler for checkbox
  const [checked, handleChecked] = useState(false);

  // data about all projects
  const projects = [
    {
      id: "yield",
      title: "projects.yield.title",
      description: "projects.yield.description",
      timeBegin: new Date("12/01/2023"),
      timeEnd: new Date("04/01/2024"),
      img: "yield.png",
      favorite: false,
    },
    {
      id: "inverter",
      title: "projects.inverter.title",
      description: "projects.inverter.description",
      timeBegin: new Date("11/01/2023"),
      timeEnd: "present",
      img: "inverter.jpeg",
      favorite: false,
    },
    {
      id: "minaelector",
      title: "projects.minaelector.title",
      description: "projects.minaelector.description",
      timeBegin: new Date("04/01/2024"),
      timeEnd: "present",
      img: "mina.png",
      favorite: true,
    },
    {
      id: "cube3",
      title: "projects.cube3.title",
      description: "projects.cube3.description",
      timeBegin: new Date("09/01/2022"),
      timeEnd: "present",
      img: "cube3.png",
      favorite: false,
    },
    {
      id: "ethernautdao",
      title: "projects.ethernautdao.title",
      description: "projects.ethernautdao.description",
      timeBegin: new Date("10/01/2022"),
      timeEnd: "present",
      img: "ethernautdao.png",
      favorite: true,
    },
    {
      id: "minabuilders",
      title: "projects.minabuilders.title",
      description: "projects.minabuilders.description",
      timeBegin: new Date("09/01/2022"),
      img: "mina.png",
      favorite: false,
    },
    {
      id: "cryptostamp",
      title: "projects.cryptostamp.title",
      description: "projects.cryptostamp.description",
      timeBegin: new Date("11/01/2021"),
      img: "post.png",
      favorite: false,
    },
    {
      id: "piswap",
      title: "projects.piswap.title",
      description: "projects.piswap.description",
      timeBegin: new Date("08/01/2020"),
      href: <a href="https://github.com/PiSwapProtocol/PiSwap-core">.</a>,
      img: "piswap.png",
      favorite: false,
    },
    {
      id: "smartsocket",
      title: "projects.smartsocket.title",
      description: "projects.smartsocket.description",
      timeBegin: new Date("07/01/2019"),
      img: "tukl.png",
      favorite: true,
    },
    {
      id: "blockchers",
      title: "projects.blockchers.title",
      description: "projects.blockchers.description",
      timeBegin: new Date("01/01/2019"),
      timeEnd: new Date("01/01/2021"),
      img: "eu.png",
      favorite: true,
    },
    {
      id: "gft",
      title: "projects.gft.title",
      description: "projects.gft.description",
      href: <a href="https://www.gft.com">.</a>,
      timeBegin: new Date("11/01/2018"),
      img: "gft.png",
      favorite: false,
    },
    {
      id: "ethlease",
      title: "projects.ethlease.title",
      description: "projects.ethlease.description",
      href: <a href="https://github.com/gretzke/ethLease">.</a>,
      timeBegin: new Date("11/01/2018"),
      img: "gft.png",
      favorite: false,
    },
    {
      id: "pay2pour",
      title: "projects.pay2pour.title",
      description: "projects.pay2pour.description",
      href: <a href="https://github.com/gretzke/pay2pour">.</a>,
      timeBegin: new Date("11/01/2018"),
      img: "gft.png",
      favorite: false,
    },

    {
      id: "novitas",
      title: "projects.novitas.title",
      description: "projects.novitas.description",
      href: <a href="https://www.novitas.de/">.</a>,
      timeBegin: new Date("07/01/2018"),
      img: "novitas.png",
      favorite: false,
    },
    {
      id: "odeon",
      title: "projects.odeon.title",
      description: "projects.odeon.description",
      timeBegin: new Date("06/01/2018"),
      img: "odeon.png",
      favorite: false,
    },
    {
      id: "cgc.audit",
      title: "projects.cgc.audit.title",
      description: "projects.cgc.audit.description",
      href: (
        <a href="https://etherscan.io/token/0xb8dc4140d627d00e8a3c933660bc5ec83ba61718">
          .
        </a>
      ),
      timeBegin: new Date("05/01/2018"),
      img: "cgc.png",
      favorite: false,
    },
    {
      id: "cgc.wallet",
      title: "projects.cgc.wallet.title",
      description: "projects.cgc.wallet.description",
      href: (
        <a href="https://github.com/gretzke/CryptoGold-Wallet/releases">.</a>
      ),
      timeBegin: new Date("05/01/2018"),
      img: "cgc.png",
      favorite: false,
    },
    {
      id: "gamertoken",
      title: "projects.gamertoken.title",
      description: "projects.gamertoken.description",
      timeBegin: new Date("02/01/2018"),
      img: "gamertoken.png",
      favorite: false,
    },
    {
      id: "invers",
      title: "projects.invers.title",
      description: "projects.invers.description",
      href: (
        <a href="https://invers.com/news/blockchain-future-of-shared-mobility/">
          .
        </a>
      ),
      timeBegin: new Date("10/01/2017"),
      img: "invers.png",
      favorite: true,
    },
    {
      id: "payout",
      title: "projects.payout.title",
      description: "projects.payout.description",
      timeBegin: new Date("09/01/2017"),
      img: "eth.png",
      favorite: false,
    },

    {
      id: "napping4charity",
      title: "projects.napping4charity.title",
      description: "projects.napping4charity.description",
      timeBegin: new Date("05/01/2015"),
      img: "napping4charity.png",
      favorite: true,
    },
  ];

  const favoriteProjects = [];

  // copy favorite projects to new array
  for (let i = 0; i < projects.length; i++) {
    if (projects[i].favorite === true) {
      favoriteProjects.push(projects[i]);
    }
  }

  // state handler for the projects currently visible on screen
  const [visibleProjects, setProjects] = useState(favoriteProjects);

  // handle the visible projects from the checkmark
  const toggleFavorites = (allprojects) => {
    if (allprojects) {
      setProjects(projects);
    } else {
      setProjects(favoriteProjects);
    }
    handleChecked(allprojects);
  };

  return (
    <div>
      <Row>
        <Col>
          <VerticalTimeline>
            <div></div>
            <div className="d-flex mt-3 justify-content-end align-items-center">
              <span className="mr-1">{t("projects.toggle.best")}</span>
              <Switch
                className="mr-1"
                onChange={toggleFavorites}
                checked={checked}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#6c757d"
                offColor="#8247e5"
                height={20}
                width={40}
                handleDiameter={14}
              />
              <span>{t("projects.toggle.all")}</span>
            </div>

            {visibleProjects.map((project) => {
              return (
                <VerticalTimelineElement
                  key={project.id}
                  className="vertical-timeline-element--work"
                  contentStyle={{ backgroundColor: "var(--dark)" }}
                  date={
                    t("date.MMMMYYYY", { date: project.timeBegin }) +
                    (project.timeEnd !== undefined
                      ? " – " + t("date.MMMMYYYY", { date: project.timeEnd })
                      : "")
                  }
                  iconStyle={{ background: "#fff", color: "#fff" }}
                  icon={
                    <Image
                      src={"./assets/img/" + project.img}
                      roundedCircle
                      className="Experience-logo"
                    />
                  }
                >
                  <h3 className="vertical-timeline-element-title">
                    {t(project.title)}
                  </h3>
                  {project.href === undefined ? (
                    <p>{t(project.description)}</p>
                  ) : (
                    <p>
                      <Trans
                        i18nKey={project.description}
                        components={project.href}
                      ></Trans>
                    </p>
                  )}
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
        </Col>
      </Row>
    </div>
  );
}
