import React from 'react';
import './App.css';

import Header from './header/Header';
import Aboutme from './header/aboutme/Aboutme';
import Experience from './tabs/experience/Experience';
import Projects from './tabs/projects/Projects';
import Skills from './tabs/skills/Skills';
import Footer from './footer/Footer';

import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

function App(props) {
	const { t } = useTranslation();

	const tabs = [
		{ key: 'experience', title: 'tabs.experience', component: <Experience></Experience> },
		{ key: 'projects', title: 'tabs.projects', component: <Projects></Projects> },
		{ key: 'skills', title: 'tabs.skills', component: <Skills></Skills> },
	];

	const tabByHash = () => {
		let key = tabs[0].key;
		if (props.location.hash === '') {
			return key;
		}
		for (let i = 0; i < tabs.length; i++) {
			if (props.location.hash === '#' + tabs[i].key) {
				return tabs[i].key;
			}
		}
		return key;
	};

	const handleSelect = (value) => {
		props.history.push('#' + value);
	};

	return (
		<div>
			<Header></Header>
			<Container fluid='lg'>
				<Aboutme></Aboutme>
				<Tabs defaultActiveKey={tabByHash()} className='App-navbar nav-justified' onSelect={handleSelect}>
					{tabs.map((tab) => {
						return (
							<Tab eventKey={tab.key} key={tab.key} title={<span>{t(tab.title)}</span>}>
								{tab.component}
							</Tab>
						);
					})}
				</Tabs>
			</Container>
			<Footer></Footer>
		</div>
	);
}

export default App;
