import React, { useState, useEffect } from "react";
import "./Header.css";
import { /*Dropdown, DropdownButton,*/ Image } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

export default function Header() {
  const headerInfo = ["header.info1", "header.info2", "header.info3"];
  const [headerInfoIndex, setHeaderInfo] = useState(0);

  const { t, i18n } = useTranslation();

  // const changeLanguage = (lang) => {
  //   i18n.changeLanguage(lang);
  // };

  useEffect(() => {
    console.log("useEffect");
    i18n.changeLanguage("en");
  }, [i18n]);

  useEffect(() => {
    setTimeout(() => {
      if (headerInfoIndex === headerInfo.length - 1) {
        setHeaderInfo(0);
      } else {
        setHeaderInfo(headerInfoIndex + 1);
      }
    }, 3000);
    return () => {};
  }, [headerInfoIndex, headerInfo.length]);

  return (
    <div className="Header shadow-sm">
      {/* <DropdownButton
        style={{ position: "absolute", right: "10px", top: "10px" }}
        size="sm"
        variant="secondary"
        title={
          <Image
            src={"./assets/img/" + i18n.language + ".png"}
            className="Header-lang-img"
          />
        }
      >
        <Dropdown.Item onClick={() => changeLanguage("de")}>
          Deutsch
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => changeLanguage("en")}>
          English
        </Dropdown.Item>
      </DropdownButton> */}

      <h1 className="display-4">
        <Trans i18nKey="header.title" />
      </h1>
      <h2>
        <Trans
          i18nKey="header.info"
          values={{ keyword: t(headerInfo[headerInfoIndex]) }}
        />
        <span className="text-primary">
          {" "}
          <Trans i18nKey={headerInfo[headerInfoIndex]} />
        </span>
      </h2>

      <div className="Header-img-container">
        <Image
          src={"./assets/img/daniel.png"}
          roundedCircle
          className="Header-img border border-3 border-primary"
        />
      </div>
    </div>
  );
}
