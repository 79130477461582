import React from "react";
import "./Skills.css";
import { Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Skills() {
  const { t } = useTranslation();

  const skills = {
    "Blockchain Development and ZK": [
      { label: "Ethereum / Solidity", percentage: 99 },
      { label: "Mina / zkApps", percentage: 80 },
      { label: "Circom2", percentage: 50 },
    ],
    "Backend & System": [
      { label: "Node JS / TypeScript", percentage: 95 },
      { label: "Python", percentage: 95 },
      { label: "C / C++", percentage: 70 },
      { label: "Assembly", percentage: 70 },
    ],
    // 'Cloud Systems': [
    // 	{ label: 'AWS', percentage: 65 },
    // 	{ label: 'Azure', percentage: 55 },
    // ],
    Frontend: [
      //   { label: "HTML / CSS / JavaScript", percentage: 99 },
      { label: "Angular", percentage: 99 },
      { label: "React", percentage: 85 },
    ],
    Application: [
      { label: "ElectronJS", percentage: 90 },
      { label: "React Native & Flutter", percentage: 60 },
      //   { label: "Swift", percentage: 60 },
    ],
  };

  const educations = [
    {
      label: "skills.education.study",
      location: "skills.education.kl",
      timeBegin: new Date("04/01/2014"),
      timeEnd: new Date("08/01/2019"),
    },
    {
      label: "skills.education.abitur",
      location: "skills.education.sim",
      timeBegin: new Date("08/01/2005"),
      timeEnd: new Date("03/01/2014"),
    },
    {
      label: "skills.education.costarica",
      location: "skills.education.sj",
      timeBegin: new Date("06/01/2011"),
      timeEnd: new Date("10/01/2011"),
    },
    {
      label: "skills.education.russia",
      location: "skills.education.mos",
      timeBegin: new Date("02/01/2009"),
      timeEnd: new Date("07/01/2009"),
    },
  ];

  const certifications = [
    {
      label: "Token Engineering Certification",
      issuer: "TE Academy",
      time: new Date("02/01/2023"),
    },
    {
      label: "Certified Ethereum Blockchain Developer",
      issuer: "ConsenSys",
      time: new Date("10/01/2019"),
    },
    {
      label: "Certified Corda Developer",
      issuer: "R3",
      time: new Date("01/01/2019"),
    },
  ];

  const languages = [
    { language: "skills.languages.de", level: "skills.languages.nativetongue" },
    { language: "skills.languages.ru", level: "skills.languages.nativetongue" },
    { language: "skills.languages.en", level: "skills.languages.fluent" },
  ];

  return (
    <Card style={{ margin: "10px 0 10px 0" }} bg="dark">
      <Card.Body>
        {Object.keys(skills).map((category) => {
          return (
            <div key={category}>
              <Row className="Skills-category">
                <Col md={3}>
                  <h4>{category}</h4>
                </Col>
                <Col md={9}>
                  <Row>
                    {skills[category].map((skill) => {
                      return (
                        <Col
                          md={6}
                          className="Skills-progressbar"
                          key={skill.label}
                        >
                          <div>
                            <h5>{skill.label}</h5>
                            <ProgressBar
                              now={skill.percentage}
                              label={skill.percentage + "%"}
                              variant="primary"
                              style={{ backgroundColor: "var(--secondary)" }}
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <hr />
            </div>
          );
        })}
        <Row>
          <Col md={3}>
            <h4>{t("skills.certification.title")}</h4>
          </Col>
          <Col md={9}>
            {certifications.map((certification) => {
              return (
                <div key={certification.label}>
                  <small>
                    {t("date.MMMMYYYY", { date: certification.time })}
                  </small>
                  <br />
                  <strong>
                    <span>{certification.label}</span>
                  </strong>
                  <p>{certification.issuer}</p>
                </div>
              );
            })}
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h4>{t("skills.education.title")}</h4>
          </Col>
          <Col md={9}>
            {educations.map((education) => {
              return (
                <div key={education.label}>
                  <small>
                    {t("date.MMMMYYYY", { date: education.timeBegin }) +
                      " – " +
                      t("date.MMMMYYYY", { date: education.timeEnd })}
                  </small>
                  <br />
                  <strong>
                    <span>{t(education.label)}</span>
                  </strong>
                  <p>{t(education.location)}</p>
                </div>
              );
            })}
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h4>{t("skills.languages.title")}</h4>
          </Col>
          <Col md={9}>
            {languages.map((language) => {
              return (
                <Row key={language.language} xs={2} md={4} lg={6}>
                  <Col>
                    <strong>{t(language.language)}:</strong>
                  </Col>
                  <Col>{t(language.level)}</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
