import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';

const getVariationOfAOrAn = function (value, capitalize) {
	const letters = ['a', 'e', 'i', 'o', 'u'];
	let firstLetter = value.substring(0, 1).toLowerCase();
	let correctWordForm = '';
	if (
		letters.find(function (l) {
			return firstLetter === l;
		})
	) {
		correctWordForm = capitalize ? 'An' : 'an';
	} else {
		correctWordForm = capitalize ? 'A' : 'a';
	}

	return correctWordForm;
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			/* translation file path */
			loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
		},
		fallbackLng: 'en',
		supportedLngs: ['de', 'en'],
		debug: false,
		/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
		ns: ['translations'],
		defaultNS: 'translations',
		fallbackNS: 'translations',
		// keySeparator: false,
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
			format: function (value, format, lng) {
				moment.locale(i18n.language);
				if (format === 'en-handle-an') return !lng || lng === 'en' ? getVariationOfAOrAn(value, false) : '';
				if (format === 'en-handle-an-capitalized') return !lng || lng === 'en' ? getVariationOfAOrAn(value, true) : '';
				if (format === 'MMMM YYYY') return value === 'present' ? i18n.t('date.present') : moment(value).format(format);
				return value;
			},
		},
		react: {
			wait: true,
		},
	});

export default i18n;
